import { Pipe, PipeTransform } from "@angular/core";
import { Address } from "./address.model";

@Pipe({
  name: "address",
})
export class AddressPipe implements PipeTransform {
  transform(address: Address): string {
    if (!address) {
      return '';
    }

    let result = `${address.line1 ? address.line1 + ' ' : ''}`;
    if (address.line2) {
      result += `${address?.line2} `;
    }
    result += `${address.city ? address.city + ',' : ''} ${address.state ?? ''} ${address.zip ?? ''}`;

    return result;
  }
}
