import { Component, Input, AfterContentInit, OnDestroy } from '@angular/core';
import { AccountWithRenewalData, DelinquentStatus, DelinquentStatusLabel } from '../account.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnumHelper, LoanStatus, LoanInfoDto, AccountActivationStage } from 'common';
import { AccountService } from '../account.service';

@Component({
  selector: 'ifb-account-profile-status-widget',
  templateUrl: './account-profile-status-widget.component.html',
  styleUrls: ['./account-profile-status-widget.component.css']
})
export class AccountProfileStatusWidgetComponent implements AfterContentInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  @Input()
  data: AccountProfileStatusWidgetData;

  @Input()
  edit: boolean;

  loanStatus = LoanStatus;

  constructor(private accountService: AccountService) {
    this._unsubscribeAll = new Subject();
  }
  ngAfterContentInit(): void {
    this.accountService.obsCurrentLoan
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        if (res)
          this.data.account = res;
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getActivationStage(activationStage: AccountActivationStage): string{
    return EnumHelper.getNameFromValue(AccountActivationStage, activationStage);
  }

  getDelinquentStatusLabel(delinquentStatus: DelinquentStatus): string {
    return DelinquentStatusLabel.get(delinquentStatus ? delinquentStatus : DelinquentStatus.Current);
  }

  getMaturityDate(): Date {
    if (!this.data.account.currentState || !this.data.account.currentState.lastDrawDate
      || !this.data.account.offer || !this.data.account.repaymentTerm)
      return null;

    const lastDrawDate = new Date(this.data.account.currentState.lastDrawDate);
    if (!lastDrawDate)
      return null;

    return new Date(lastDrawDate.setMonth(lastDrawDate.getMonth() + this.data.account.repaymentTerm));
  }

}

export interface AccountProfileStatusWidgetData {
  account: AccountWithRenewalData;
  accountInfo: LoanInfoDto;
}
