import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerData, CustomerComponentData } from '../customer.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readOnly, readWrite } from '../../user/user-permission/user-permission.data';
import { routerTransition } from '../../shared/animations/router.animations';


import * as _ from 'lodash';

@Component({
  selector: 'ifb-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
  animations: routerTransition
})
export class CustomerProfileComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  customerInfoData: CustomerData = {};
  canEditCustomerInfo: boolean;
  canEditCustomerKBA: boolean;
  saleforceViewMode: boolean;
  canReadCustomerIdentificationData: boolean;

  constructor(private route: ActivatedRoute, private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(params => this.saleforceViewMode = params.uniqueId !== undefined);

    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));

    this.userPermissionService.granted([readWrite('servicing-customers')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canEditCustomerInfo = res);

    this.userPermissionService.granted([readWrite('servicing-customers-identification-data')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canEditCustomerKBA = res);

    this.userPermissionService.granted([readOnly('servicing-customers-identification-data')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canReadCustomerIdentificationData = res);
  }

  dataSet(data: CustomerComponentData) {
    this.customerInfoData = data.customer;
    this.customerInfoData.accountType = _.trim(data.customer.accountType, '#$');
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}


