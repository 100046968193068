import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrokerBaseData, BrokerData, BrokerExData, BrokerQueryParams, BrokerQueryResult } from './broker.model';
import { BankAccount, ConfigService, ResourceService } from 'common';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  private readonly brokerUrl = `${this.settings.brokers.url}/api/broker`;
  private readonly brokerBankUrl = `${this.settings.brokers.url}/api/broker-bank`;

  prevBrokerListUrl: string;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private settings: AppSettings,
    private configService: ConfigService) { }

  query(params: BrokerQueryParams): Observable<BrokerQueryResult> {
    return this.http.get<BrokerQueryResult>(`${this.brokerUrl}`, {
      headers: this.configService.environment.cache.longLived,
      params: <any>params
    });
  }

  get(id: number): Observable<BrokerExData> {
    return this.http.get<BrokerExData>(`${this.brokerUrl}/${id}`, {
      headers: this.configService.environment.cache.longLived
    });
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.brokerUrl}/${id}`);
  }

  create(broker: BrokerBaseData): Observable<BrokerData> {
    return this.http.post<BrokerData>(`${this.brokerUrl}`, broker);
  }

  download(params: BrokerQueryParams) {
    this.resourceService.download(`${this.brokerUrl}/csv`, "brokers.csv", params);
  }

  update(id: number, broker: BrokerBaseData): Observable<BrokerData> {
    return this.http.put<BrokerData>(`${this.brokerUrl}/${id}`, broker);
  }

  updateCommission(id: number, updateCommand: any): Observable<any> {
    return this.http.put<any>(`${this.brokerUrl}/${id}/commission`, updateCommand);
  }

  updateCompany(id: number, updateCommand: any): Observable<any> {
    return this.http.put<any>(`${this.brokerUrl}/${id}/company`, updateCommand);
  }

  updateStatus(id: number, updateCommand: any): Observable<any> {
    return this.http.put<any>(`${this.brokerUrl}/${id}/status`, updateCommand);
  }

  updateInfo(id: number, updateCommand: any): Observable<any> {
    return this.http.put<any>(`${this.brokerUrl}/${id}/info`, updateCommand);
  }

  updateAdvisor(id: number, advisorId: number): Observable<any> {
    return this.http.put<any>(`${this.brokerUrl}/${id}/advisor/${advisorId}`, null);
  }

  getBrokerBankAccountListGrouped(brokerId: number): Observable<any> {
    return this.http.get<any>(`${this.brokerBankUrl}/grouped?broker=${brokerId}`);
  }

  getBrokerBankAccount(brokerId: number, bankAccountId: number): Observable<BankAccount> {
    return this.http.get<BankAccount>(`${this.brokerBankUrl}/${bankAccountId}?broker=${brokerId}`);
  }

  createBrokerBankAccount(brokerId: number, bankAccount: BankAccount): Observable<any> {
    return this.http.post<any>(`${this.brokerBankUrl}?broker=${brokerId}`, bankAccount);
  }

  updateBrokerBankAccount(brokerId: number, bankAccount: BankAccount): Observable<any> {
    return this.http.put<any>(`${this.brokerBankUrl}?broker=${brokerId}`, bankAccount);
  }

  deleteBrokerBankAccount(brokerId: number, bankAccountId: number): Observable<any> {
    return this.http.delete<any>(`${this.brokerBankUrl}/${bankAccountId}?broker=${brokerId}`);
  }

}
