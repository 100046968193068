import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  AppLoaderInterceptor,
  OAuthInterceptor,
  ConvertParamsToUrlInterceptor,
  ErrorInterceptor,
  CacheInterceptor,
  CoreModule,
  ErrorsModule,
} from "common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AdminModule } from "./admin/admin.module";
import { UserModule } from "./user/user.module";
import { TransactionModule } from "./transaction/transaction.module";
import { AccountModule } from "./account/account.module";
import { SharedModule } from "./shared/shared.module";
import { HomeModule } from "./home/home.module";
import { AuthModule } from "./auth/auth.module";
import { CustomerExportModule } from "./customer/customer.module";
import { AuditTrailModule } from "./shared/audit-trail/audit-trail.module";
import { AboutPageModule } from "./about/about-page.module";
import { BrokerModule } from "./broker/broker.module";
import { BankingModule } from "./banking/banking.module";
import { CompanyModule } from "./entity/entity.module";
import { AdvisorModule } from "./advisor/advisor.module";
import { LogModule } from "./log/log.module";
import { ApplicationModule } from "./application/application.module";
import { DocumentModule } from "./document/document.module";
import { OfferBundleModule } from "./offer-bundle/offer-bundle.module";
import { OfferModule } from "./offer/offer.module";
import { CurrencyPipe, PercentPipe } from "@angular/common";
import { CompetitorsModule } from "./competitors/competitors.module";
import { BrokerUsersModule } from "./feature-modules/broker-users/broker-users.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AdminModule,
    UserModule,
    TransactionModule,
    BrokerModule,
    BrokerUsersModule,
    CustomerExportModule,
    AccountModule,
    SharedModule,
    HomeModule,
    AuthModule,
    AuditTrailModule,
    AboutPageModule,
    BankingModule,
    CompanyModule,
    AdvisorModule,
    LogModule,
    ApplicationModule,
    DocumentModule,
    OfferBundleModule,
    OfferModule,
    CompetitorsModule,
    CoreModule,
    ErrorsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppLoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConvertParamsToUrlInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    CurrencyPipe,
    PercentPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
