import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { CashFlowAnalysisService } from '../cash-flow-analysis.service';

@Component({
  selector: 'ifb-application-summary',
  templateUrl: '../cash-flow-analysis.component.html',
  styleUrls: ['../cash-flow-analysis.component.scss']
})
export class ApplicationSummaryComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: string;

  constructor(
    private route: ActivatedRoute,
    private _cashFlowAnalysisService: CashFlowAnalysisService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data && data.application)
          this.getData(data.application.uniqueId);
      });
  }

  getData(sfApplicationId: string): void {
    this._cashFlowAnalysisService.getApplicationSummary(sfApplicationId)
      .subscribe(data => this.data = data);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
