import { Component, Input, ViewChild, EventEmitter, Output, OnInit } from '@angular/core';
import { TransactionData, TransactionActionCommand } from '../transaction.model';
import { TransactionListUpdateDialogComponent } from '../transaction-list-update-dialog/transaction-list-update-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TransactionService } from '../transaction.service';
import { Subscription } from 'rxjs';
import { MessageService, TransactionStatus, TransactionType, parseNumber } from 'common';
import { UserPermissionItem } from '../../user/user-permission/user-permission.service';
import { AccountService } from '../../account/account.service';
import { ActivatedRoute, Params, Router, UrlSerializer } from '@angular/router';
import { FailureReasonData } from '../../admin/failure-reason/failure-reason.model';

@Component({
  selector: 'ifb-transaction-edit-menu',
  templateUrl: './transaction-edit-menu.component.html',
  styleUrls: ['./transaction-edit-menu.component.css']
})
export class TransactionEditMenuComponent implements OnInit {

  private saveSub: Subscription;

  get TransactionStatus() { return TransactionStatus; }
  get TransactionType() { return TransactionType; }

  constructor(
    private transactionService: TransactionService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private serializer: UrlSerializer
  ) {}

  @Input()
  tx: TransactionData;

  @Input()
  failureReasons: FailureReasonData;

  @Input()
  canEditSubTransactions: boolean;

  @Input()
  CanUserMoveTransactionToStatusGrantedPermissions: UserPermissionItem[];

  @Input()
  expandedDetail: boolean;

  @Input()
  backToListUrl: string;

  @Output()
  statusChanged = new EventEmitter<any>();

  @Output()
  expandedDetailChanged = new EventEmitter<any>();

  @ViewChild(TransactionListUpdateDialogComponent) transactionListUpdateDialog;

  transactionDetailsQueryParams: Params;

  ngOnInit(): void {
    this.transactionDetailsQueryParams = { backTo: this.backToListUrl };
  }

  updateDialog(status) {
    TransactionListUpdateDialogComponent.show(this.dialog, this.tx.loanNumber, status, this.failureReasons, this.tx.status, this.tx.type, this.tx.isWelcomeCallWarningSuitable)
      .subscribe(
        data => {
          if (!data)
            return;
          const updateCommand: TransactionActionCommand = {
            id: this.tx.id,
            loanId: this.tx.accountId,
            status: status,
            notes: [data.note],
            failureReasons: data.reasons,
            date: this.tx.date,
            processDate: this.tx.processDate,
            underwritingDate: this.tx.underwritingDate,
            financeDate: this.tx.financeDate,
            completeDate: this.tx.completeDate,
            cancellationDate: this.tx.cancellationDate,
            competitors: data?.competitors?.map(c => c.id)
          };
          this.saveSub = this.transactionService
            .update(updateCommand)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        });
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveSuccessHandler() {
    if (this.route.snapshot.params.uniqueId)
      this.accountService.getByUniqueId(this.route.snapshot.params.uniqueId).subscribe(data => {
        this.saveSuccessCompleted(data);
      });
    else {
      const loanId = parseNumber(this.route.snapshot.params.id) || this.tx.accountId;
      this.accountService.getWithRenewal(loanId).subscribe(data => {
        this.saveSuccessCompleted(data);
      });
    }
  }

  saveSuccessCompleted(data) {
    this.accountService.onCurrentLoanChanged.next(data);
    this.saveSubClear();
    this.statusChanged.emit();
  }

  transactionDetailsLink(tx: TransactionData) {
    return `/account/${tx.accountId}/transaction/${tx.id}`;
  }

  transactionDetailsLinkAdmin(tx: TransactionData) {
    return `/account/${tx.accountId}/transaction/${tx.id}${this.canEditSubTransactions ? '/admin' : ''}`;
  }

  onExpandedDetailChanged() {
    this.expandedDetailChanged.emit();
  }

  viewNewTab(tx) {
    const tree = this.router.createUrlTree([`/account/${tx.accountId}/transaction/${tx.id}/view-mode`], { queryParams: this.transactionDetailsQueryParams });
    window.open(this.serializer.serialize(tree));
  }
}
