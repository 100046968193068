import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogActionComponent, MessageService, QueryParamsService, parseNumber } from "common";
import { UserPermissionService } from "projects/backoffice/src/app/user/user-permission/user-permission.service";
import { readWrite } from "projects/backoffice/src/app/user/user-permission/user-permission.data";
import { BrokerUserFacade } from "../../domain/+state/broker-user.facade";
import {
  BrokerUserListItemData,
  BrokerUserRole,
  BrokerUserRoleLabel,
  BrokerUserStatus,
  BrokerUserStatusLabel,
  BrokerUsersQueryParams,
  BrokerUsersQueryResult,
  UpdateBrokerUserStatusRequest,
} from "../../domain/models/broker-user.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { routerTransition } from "../../../../shared/animations/router.animations";
import { BrokerUserListFacade } from "./broker-user-list.facade";
import { MatDialog } from "@angular/material/dialog";
import { getNotificationSentDialogData } from "../../broker-users.data";

@UntilDestroy()
@Component({
  selector: "ifb-broker-user-list",
  templateUrl: "./broker-user-list.component.html",
  styleUrls: ["./broker-user-list.component.scss"],
  providers: [BrokerUserListFacade],
  animations: routerTransition
})
export class BrokerUserListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) matSort?: MatSort;

  queryParams: BrokerUsersQueryParams;
  readWriteBrokerUsers: boolean;
  brokerId: number;
  data: BrokerUsersQueryResult = {
    values: [],
    totalCount: 0,
  };
  tableColumns = [
    "firstName",
    "lastName",
    "mobilePhone",
    "email",
    "role",
    "active",
    "lastLoggedOnUtc",
    "firstAgentApplicationsCount",
    "assignedAgentApplicationsCount",
    "overflow",
  ];

  readonly BrokerUserRole = BrokerUserRole;
  readonly BrokerUserRoleLabel = BrokerUserRoleLabel;
  readonly BrokerUserStatusLabel = BrokerUserStatusLabel;
  readonly BrokerUserStatus = BrokerUserStatus;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private brokerUserListFacade: BrokerUserListFacade,
    private queryParamsService: QueryParamsService,
    private userPermissionService: UserPermissionService,
    private brokerUserFacade: BrokerUserFacade,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.brokerId = parseNumber(this.route.snapshot.paramMap.get("id"));
    this.userPermissionService
      .granted([readWrite("brokers-broker-users")])
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.readWriteBrokerUsers = res));

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((it) => {
      this.queryParams = {
        brokerId: this.brokerId,
        roles: [BrokerUserRole.Administrator, BrokerUserRole.User],
        ...this.queryParamsService.init(it),
      };

      this.paginator.pageSize = this.queryParams.limit;
      this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;

      this.refreshUsers();
    });
  }

  private refreshUsers() {
    const queryParams: BrokerUsersQueryParams = {
      brokerId: this.brokerId,
      roles: [BrokerUserRole.Administrator, BrokerUserRole.User],
      limit: this.queryParams.limit,
      skip: this.queryParams.skip,
      sort: this.queryParams.sort,
    };
    this.brokerUserFacade.getBrokerUsers(queryParams).subscribe((res) => {
      this.data = res;
    });
  }

  filter() {
    this.brokerUserListFacade.filter(this.paginator, this.matSort);
  }

  onEdit(brokerUserId: number) {
    this.router.navigate([`/broker/${this.brokerId}/users/${brokerUserId}`])
  }

  onDisable(brokerUser: BrokerUserListItemData) {
    this.brokerUserListFacade
      .showBrokerUserDisableDialog(brokerUser)
      .subscribe((res) => {
        if (res) {
          const request: UpdateBrokerUserStatusRequest = {
            id: brokerUser.id,
            active: false,
          };

          this.brokerUserFacade.updateBrokerUserStatus(request).subscribe({
            next: () => {
              this.refreshUsers();
              this.messageService.success(
                "User has been successfully deactivated."
              );
            },
            error: () =>
              this.messageService.error("User cannot be deactivated."),
          });
        }
      });
  }

  onResendInvitation(brokerUser: BrokerUserListItemData) {
    this.brokerUserFacade
    .resendInvitation(brokerUser.id)
    .pipe(untilDestroyed(this))
    .subscribe({
      next: () => this.openNotificationSentDialog(brokerUser),
      error: () => this.messageService.error("Error occured while trying to resend activation email.")
    });
  }

  private openNotificationSentDialog(brokerUser: BrokerUserListItemData) {
    DialogActionComponent.show(this.dialog, getNotificationSentDialogData(brokerUser)).subscribe(() => {
      this.router.navigate([], { queryParams: this.queryParams })
    });
  }
}
