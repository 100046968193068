import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { EnumHelper, FormHelper, TransactionStatus, TransactionStatusLabel, TransactionType } from "common";
import { FailureReasonUsage } from "../../admin/failure-reason/failure-reason.model";
import { map } from "rxjs/operators";
import { UserPermissionService } from "../../user/user-permission/user-permission.service";
import { readOnly } from "../../user/user-permission/user-permission.data";
import { CompetitorHintData } from "../../competitors/models/competitor.model";

@Component({
  selector: 'ifb-transaction-list-update-dialog',
  templateUrl: './transaction-list-update-dialog.component.html',
  styleUrls: ['./transaction-list-update-dialog.component.css']
})
export class TransactionListUpdateDialogComponent {
  canReadCompetitors: boolean;
  isCompetitorsSpecifiable$: Observable<boolean>;
  form: UntypedFormGroup;
  public TransactionStatus = TransactionStatus;
  public TransactionType = TransactionType;
  public isWelcomeCallWarning: boolean;

  get FailureReasonUsage() { return FailureReasonUsage; }

  constructor(public dialogRef: MatDialogRef<TransactionListUpdateDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userPermissionService: UserPermissionService) {
      this.form = this.formBuilder.group({
        note: [undefined],
        reasons: [undefined],
        competitors: [undefined]
      });

      this.isWelcomeCallWarning = this.data.isWelcomeCallWarningSuitable 
        && this.data.transactionType === TransactionType.Draw 
        && this.data.status === TransactionStatus.Approved 
        && this.data.previousStatus === TransactionStatus.Pending;

      if (this.isWelcomeCallWarning) {
        let noteControl = this.form.get('note');
        noteControl.setValidators(Validators.required);
      }

      this.userPermissionService.granted([readOnly('admin-competitors')])
        .subscribe(res => this.canReadCompetitors = res);

      this.isCompetitorsSpecifiable$ = this.form.valueChanges.pipe(
        map(form => form?.reasons),
        map(selectedReasons =>
          selectedReasons?.some(selectedReason =>
            this.data.failureReasons?.find(reason =>
              reason.code === selectedReason
            )?.allowToSpecifyCompetitors)
        )
      );
    }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (!this.form.valid)
      return;
    this.dialogRef.close(this.form.value);
  }

  onCompetitorsChange(competitors: CompetitorHintData[]) {
    this.form.get('competitors').setValue(competitors);
    this.form.get('competitors').markAsDirty();
    this.form.get('competitors').markAsTouched();
    this.form.get('competitors').updateValueAndValidity();
  }

  showStatus(status: number): string {
    return EnumHelper.getMappedNameFromValue(TransactionStatus, TransactionStatusLabel, status);
  }

  static show(dialog: MatDialog, loanNumber, status, failureReasons, previousStatus, transactionType, isWelcomeCallWarningSuitable): Observable<any> {
    return dialog.open(TransactionListUpdateDialogComponent, {
      width: '510px',
      data: { loanNumber, status, failureReasons, previousStatus, transactionType, isWelcomeCallWarningSuitable },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
