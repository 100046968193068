import { parseNumber } from 'common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { routerTransition } from '../../shared/animations/router.animations';
import { BrokerExData } from '../broker.model';
import { BusinessAdvisorPickerComponent } from '../../shared/business-advisor/business-advisor-picker/business-advisor-picker.component';
import { BrokerService } from '../broker.service';

@Component({
  selector: 'ifb-broker-profile',
  templateUrl: './broker-profile.component.html',
  styleUrls: ['./broker-profile.component.css'],
  animations: routerTransition
})
export class BrokerProfileComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  canEditBrokerInfo = false;
  data: BrokerProfileComponentData;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private brokerService: BrokerService,
    private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));

    this.userPermissionService.granted([readWrite('brokers-brokers')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canEditBrokerInfo = res);
  }

  dataSet(data: BrokerProfileComponentData) {
    this.data = data;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  changeAdvisor() {
    BusinessAdvisorPickerComponent.show(this.dialog)
      .subscribe(res => {
        if (res) {
          if (!res.id)
            return;

          this.brokerService.updateAdvisor(this.data.broker.id, parseNumber(res.id))
            .subscribe(() => {
              this.brokerService.get(this.data.broker.id).subscribe(broker => {
                this.data.broker = broker;
              });
            });
        }
      });
  }
}

export interface BrokerProfileComponentData {
  broker?: BrokerExData;
}
