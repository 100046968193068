export type UserPermissionAccess = 'none' | 'read-only' | 'read-write';

export interface UserPermissionData {
  id: UserPermissionId;
  access: UserPermissionAccess;
}

export interface UserPermissionDef {
  id: UserPermissionId;
  name: string;
  allowedAccessType: string[];
}

export type UserPermissionId =
  | 'none'
  | 'admin-clients'
  | 'admin-roles'
  | 'admin-tokens'
  | 'admin-users'
  | 'admin-api-keys'
  | 'admin-audit-log'
  | 'admin-failure-reasons'
  | 'creditmodel-monitoringresults'
  | 'creditmodel-offerresults'
  | 'creditmodel-preapprovalresults'
  | 'creditmodel-weightings'
  | 'origination-applications'
  | 'origination-leads'
  | 'servicing-accounts'
  | 'servicing-account-states'
  | 'servicing-account-offer'
  | 'servicing-account-status'
  | 'servicing-account-other-info'
  | 'servicing-account-modification'
  | 'servicing-account-edit-notes'
  | 'servicing-account-sensitive-data'
  | 'servicing-account-npl-economics'
  | 'servicing-account-documents'
  | 'servicing-account-documents-generation'
  | 'servicing-transactions'
  | 'servicing-companies'
  | 'servicing-customers'
  | 'servicing-customers-loans'
  | 'servicing-customers-pending-loans'
  | 'servicing-customers-sensitive-data'
  | 'servicing-customers-registration-data'
  | 'servicing-customers-identification-data'
  | 'servicing-advisors'
  | 'servicing-advisors-assignment'
  | 'servicing-deleting-advisors'
  | 'brokers-brokers'
  | 'brokers-brokers-sensitive-data'
  | 'brokers-broker-users'
  | 'servicing-synced-account'
  | 'servicing-synced-transaction'
  | 'servicing-synced-customer'
  | 'servicing-delete-synced-customer'
  | 'servicing-synchronize-synced-customer'
  | 'servicing-pull-synced-bank-account-extra-info'
  | 'servicing-editing-processed-transactions'
  | 'servicing-subtransactions'
  | 'servicing-editing-pending-transactions'
  | 'servicing-editing-underwritten-transactions'
  | 'servicing-editing-transaction-dates'
  | 'servicing-can-set-transaction-date-in-the-past'
  | 'servicing-editing-transaction-amount'
  | 'servicing-editing-transaction-loan'
  | 'servicing-editing-transaction-notes'
  | 'servicing-deleting-transactions'
  | 'servicing-underwriting-transactions'
  | 'servicing-cancelling-transactions'
  | 'servicing-moving-transactions-to-pending'
  | 'servicing-processing-transactions'
  | 'servicing-entities'
  | 'servicing-entities-sensitive-data'
  | 'servicing-entity-documents'
  | 'servicing-bank-accounts'
  | 'servicing-bank-accounts-verification'
  | 'servicing-bank-accounts-deletion'
  | 'servicing-bank-analysis'
  | 'admin-logs'
  | 'maintenance-maintain-loan'
  | 'maintenance-documents-generation'
  | 'servicing-applications'
  | 'servicing-application-documents'
  | 'servicing-promotions'
  | 'admin-feature-flags'
  | 'admin-tenants'
  | 'servicing-promotions'
  | 'servicing-offer-bundles'
  | 'servicing-offers'
  | 'admin-competitors';

export function readOnly(id: UserPermissionId): UserPermissionData {
  return { id: id, access: 'read-only' };
}

export function readWrite(id: UserPermissionId): UserPermissionData {
  return { id: id, access: 'read-write' };
}
