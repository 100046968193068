import { NgModule } from "@angular/core";
import { CommonModule } from 'common';
import { SharedModule } from "../shared/shared.module";
import { UserModule } from "../admin/user/user.module";

import { BrokerRoutingModule } from "./broker-routing.module";
import { BrokerListComponent } from "./broker-list/broker-list.component";
import { BrokerListFilterDialogComponent } from "./broker-list-filter-dialog/broker-list-filter-dialog.component";
import { BrokerAppBarTitleComponent } from './broker-app-bar-title/broker-app-bar-title.component';
import { BrokerProfileComponent } from './broker-profile/broker-profile.component';
import { BrokerStatusWidgetComponent } from './broker-status-widget/broker-status-widget.component';
import { BrokerCompanyWidgetComponent } from './broker-company-widget/broker-company-widget.component';
import { BrokerOtherInfoWidgetComponent } from './broker-other-info-widget/broker-other-info-widget.component';
import { BrokerCommissionInfoWidgetComponent } from './broker-commission-widget/broker-commission-widget.component';
import { BrokerBrandingWidgetComponent } from './broker-branding-widget/broker-branding-widget.component';
import { BrokerCommissionDetailsComponent } from './broker-commission-details/broker-commission-details.component';
import { BrokerCompanyDetailsComponent } from './broker-company-details/broker-company-details.component';
import { BrokerStatusDetailsComponent } from './broker-status-details/broker-status-details.component';
import { BrokerOtherInfoDetailsComponent } from './broker-other-info-details/broker-other-info-details.component';
import { BrokerBankAccountListComponent } from './broker-bank-account-list/broker-bank-account-list.component';
import { BrokerBankAccountWidgetComponent } from './broker-bank-account-widget/broker-bank-account-widget.component';
import { BrokerBankAccountDetailsComponent } from './broker-bank-account-details/broker-bank-account-details.component';
import { BrokerDetailsComponent } from './broker-details/broker-details.component';
import { BrokerUpdateDialogComponent } from './broker-update-dialog/broker-update-dialog.component';
import { BrokerCreateDialogComponent } from './broker-create-dialog/broker-create-dialog.component';
import { BrokerActivityWidgetComponent } from './broker-activity-widget/broker-activity-widget.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        UserModule,
        BrokerRoutingModule
    ],
    declarations: [
        BrokerListComponent,
        BrokerListFilterDialogComponent,
        BrokerAppBarTitleComponent,
        BrokerProfileComponent,
        BrokerStatusWidgetComponent,
        BrokerCompanyWidgetComponent,
        BrokerOtherInfoWidgetComponent,
        BrokerCommissionInfoWidgetComponent,
        BrokerBrandingWidgetComponent,
        BrokerCommissionDetailsComponent,
        BrokerCompanyDetailsComponent,
        BrokerStatusDetailsComponent,
        BrokerOtherInfoDetailsComponent,
        BrokerBankAccountListComponent,
        BrokerBankAccountWidgetComponent,
        BrokerBankAccountDetailsComponent,
        BrokerDetailsComponent,
        BrokerUpdateDialogComponent,
        BrokerCreateDialogComponent,
        BrokerActivityWidgetComponent
    ]
})
  export class BrokerModule { }
