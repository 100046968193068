<h1 mat-dialog-title>Update transaction status?</h1>
<mat-dialog-content class="mat-typography">

  <p *ngIf="data.status !== TransactionStatus.Pending && !(isWelcomeCallWarning)">
    You are updating {{data.loanNumber}}’s transaction status to '{{showStatus(data.status) | lowercase}}'.
  </p>
  <p *ngIf="isWelcomeCallWarning">
    A Welcome Call is required and has not been completed. To proceed, a reason for the transaction update is required below.
  </p>
  <p *ngIf="data.status === TransactionStatus.Pending">
    You are updating {{data.loanNumber}}’s transaction {{showStatus(data.status) | lowercase}} status.
  </p>

  <div *ngIf="data.status === TransactionStatus.Rejected || data.status === TransactionStatus.Declined" class="info">
    <p>To proceed, select reject reason(s).</p>
    <ifb-transaction-reject-input [fc]="form.get('reasons')" [usage]="FailureReasonUsage.ResidualUnderwriting"></ifb-transaction-reject-input>
    <ifb-competitors-select-search *ngIf="canReadCompetitors && (isCompetitorsSpecifiable$ | async)"
      [selectedCompetitorIds]="form.get('competitors').value"
      (competitorsChange)="onCompetitorsChange($event)">
    </ifb-competitors-select-search>
  </div>
  <div *ngIf="data.status === TransactionStatus.Pending" class="info">
    <p>To proceed, select pending reason(s).</p>
    <ifb-transaction-reject-input [fc]="form.get('reasons')" [usage]="FailureReasonUsage.PendingReason"></ifb-transaction-reject-input>
  </div>

  <div>
    <ifb-note-card [fc]="form.get('note')" [hideButtons]="true" [showNotes]="false"></ifb-note-card>
  </div>

</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" (click)="save()">Update</button>
</div>
