<div fxLayout="column" fxLayoutAlign="stretch">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
        <button mat-icon-button (click)="download(queryParams)" class="secondary-text" matTooltip="Download">
          <mat-icon>get_app</mat-icon>
        </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button mat-menu-item (click)="download(queryParams)">
            <mat-icon>get_app</mat-icon>
            <span>Download</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div>
    <ifc-app-scroll-container [default]="true">
      <div class="table-container">
        <div class="message-box info" *ngIf="!data.accountQueryResult.totalCount" fxLayout="row" fxLayoutGap="12px"
          fxLayoutAlign="start center">
          <mat-icon class="icon">info</mat-icon>
          <span>No data found</span>
        </div>
        <table *ngIf="data.accountQueryResult.totalCount" mat-table matSort
          [dataSource]="data.accountQueryResult.values" (matSortChange)="sortData($event)">

          <ng-container matColumnDef="alligatorDataIcon">
            <th scope="col" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="table-cell icon-cell" (click)="accountDetailsLink(row)">
              <div [ngClass]="row | alligatorDataClass">
                <mat-icon matTooltip="{{row | alligatorDataTooltip}}" [inline]="true" class="small">
                  {{row | alligatorDataIcon}}</mat-icon>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="loanNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="loanNumber"> Loan number </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.loanNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="applicationNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="applicationNumber"> Application number </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.applicationNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              <span *ngIf="row.status === 1">Open</span>
              <ifb-account-status-icon-chip *ngIf="row.status !== 1 && row.status !== undefined" [status]="row.status">
              </ifb-account-status-icon-chip>
            </td>
          </ng-container>

          <ng-container matColumnDef="productType">
              <th mat-header-cell *matHeaderCellDef> Product type </th>
              <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
                {{row.productName}}
              </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="description"> Case name </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.description}}
            </td>
          </ng-container>

          <ng-container matColumnDef="entityName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="entityData.name"> Legal entity name </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.entityName}}
            </td>
          </ng-container>

          <ng-container matColumnDef="creditLimit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="offer.amount" arrowPosition="before" class="right-align"> Offer amount </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)" >
              {{row.offer.amount | currency}}
            </td>
          </ng-container>

          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="currentState.availableBalance" arrowPosition="before" class="right-align"> Available funds </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)" >
              {{row.loanInfo?.loanAvailableFunds | currency}}
            </td>
          </ng-container>

          <ng-container matColumnDef="msaCreditLimit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="entityData.msaTerms.offerAmount" arrowPosition="before" class="right-align"> MSA offer amount </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)" >
              {{row.loanInfo?.msaCreditLimit | currency}}
            </td>
          </ng-container>

          <ng-container matColumnDef="msaAvailableFunds">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="entityData.msaTerms.currentAvailableBalance" arrowPosition="before" class="right-align"> MSA available funds </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)" >
              {{row.loanInfo?.msaAvailableFunds | currency}}
            </td>
          </ng-container>

          <ng-container matColumnDef="modification">
            <th mat-header-cell *matHeaderCellDef> Loan modification </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)">
              {{getLoanModification(row.modificationFlag)}} </td>
          </ng-container>

          <ng-container matColumnDef="principal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="currentState?.principalBalance" arrowPosition="before" class="right-align"> Principal </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)" >
              {{row.currentState?.principalBalance | currency}} </td>
          </ng-container>

          <ng-container matColumnDef="accruedInterest">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="currentState?.accruedInterest" arrowPosition="before" class="right-align"> Interest </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)" >
              {{row.currentState?.accruedInterest | currency}} </td>
          </ng-container>

          <ng-container matColumnDef="paymentAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="currentState?.paymentAmount" arrowPosition="before" class="right-align"> Upcoming payment </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)" >
              {{row.loanInfo?.upcomingPayment | currency}} </td>
          </ng-container>

          <ng-container matColumnDef="paymentDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="currentState?.paymentDueDate"> Upcoming payment date </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.loanInfo?.paymentDueDate | date: 'shortDate'}} </td>
          </ng-container>

          <ng-container matColumnDef="interestRate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="interestRate" arrowPosition="before" class="right-align"> Rate </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)">
              <span *ngIf="row.productCode !== ProductCode['Term Loan']">
                {{row.interestRate | number: '1.2-2'}}%
              </span>
              <span *ngIf="row.productCode === ProductCode['Term Loan']">
                {{row.interestRate / 100 + 1 | number: '1.4-4'}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="drawDownFee">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="currentDrawDownFee" arrowPosition="before" class="right-align"> Fee </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)">
              <span *ngIf="row.drawDownFee && row.drawDownFeeType === FeeType.Percentage">{{row.drawDownFee | number: '1.2-2'}}%</span>
              <span *ngIf="row.drawDownFeeType === FeeType.Flat">{{row.drawDownFee | currency}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="drawTerm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="drawDownTerm"> Draw term </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.offer?.drawTerm}} months </td>
          </ng-container>

          <ng-container matColumnDef="repaymentTerm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="repaymentTerm"> Repayment term </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.repaymentTerm}} months </td>
          </ng-container>

          <ng-container matColumnDef="paymentFrequency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="offer.paymentFrequency"> Payment frequency </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{getaccountOfferPaymentFrequenciesLabel(row.offer.paymentFrequency)}} </td>
          </ng-container>

          <ng-container matColumnDef="advisorName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="advisorData.firstName"> Business advisor </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.advisorName}} </td>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="createdOn"> Created on </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.createdOn | date:'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="activationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="maintenanceState.firstDrawDate"> Activation date
            </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.activationStageLog?.Activated | date:'medium'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="renewalDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="renewalDate"> Renewal date </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{(row.renewalDate | date:'medium') || '-'}} </td>
          </ng-container>

          <ng-container matColumnDef="paymentDay">
            <th mat-header-cell *matHeaderCellDef> Payment day </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.maintenanceState.paymentDay}}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastAutoPaymentAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="maintenanceState.lastAutoPaymentAmount" arrowPosition="before" class="right-align"> Last
              auto-payment
            </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="accountDetailsLink(row)" > {{
              (row.maintenanceState ? row.maintenanceState.lastAutoPaymentAmount : undefined) | currency:'$'}} </td>
          </ng-container>

          <ng-container matColumnDef="lastAutoPaymentDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="maintenanceState.lastAutoPaymentDate"> Last
              auto-payment date
            </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" > {{
              (row.maintenanceState ? row.maintenanceState.lastAutoPaymentDate : undefined) | date:'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="autoPayMode">
            <th mat-header-cell *matHeaderCellDef> Auto-payment mode </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{getAutoPaymentMode(row.autoPayMode)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="accrueInterest">
            <th mat-header-cell *matHeaderCellDef> Accrue interest </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.accrueInterest}}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastReAmortizationDate">
            <th mat-header-cell *matHeaderCellDef> Last re-amortization date </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.lastReAmortizationDate | date: 'medium'}}
            </td>
          </ng-container>          

          <ng-container matColumnDef="modificationStartDate">
            <th mat-header-cell *matHeaderCellDef> Modification start date </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.modificationStartDate | date: 'medium'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="modificationEndDate">
            <th mat-header-cell *matHeaderCellDef> Modification end date </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.modificationEndDate | date: 'medium'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="originalPaymentAmount">
            <th mat-header-cell *matHeaderCellDef> Previous payment </th>
            <td mat-cell *matCellDef="let row" class="table-cell  right-align" (click)="accountDetailsLink(row)" >
              {{row.originalPaymentAmount | currency}}
            </td>
          </ng-container>
          
          <ng-container matColumnDef="originalRepaymentTerm">
            <th mat-header-cell *matHeaderCellDef> Previous repayment term </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="accountDetailsLink(row)" >
              {{row.originalRepaymentTerm}}
            </td>
          </ng-container>

          <ng-container matColumnDef="salesForceID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="uniqueId"> Salesforce loan ID </th>
            <td mat-cell *matCellDef="let row" class="table-cell">
              <a *ngIf="row.uniqueId" href="{{salesforceLink(row.uniqueId)}}" target="_blank"> {{row.uniqueId}} </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="salesForceApplicationID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="applicationUniqueId"> Salesforce application ID </th>
            <td mat-cell *matCellDef="let row" class="table-cell">
              <a *ngIf="row.applicationUniqueId" href="{{salesforceLink(row.applicationUniqueId)}}" target="_blank">
                {{row.applicationUniqueId}} </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="edit" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td (click)="$event.stopPropagation()" mat-cell *matCellDef="let row" class="action-icon"  ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
                <button mat-icon-button (click)="accountDetailsLink(row, true)">
                    <mat-icon>open_in_new</mat-icon>
                </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true;" class="table-header"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" class="table-row"></tr>
        </table>
      </div>
    </ifc-app-scroll-container>
  </div>

  <div [hidden]="data.accountQueryResult.totalCount <= 10">
    <mat-paginator #paginator showFirstLastButtons [length]="data.accountQueryResult.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)">
    </mat-paginator>
  </div>

</div>
