<ifc-app-scroll-container [widgetsWithTopHeader]="true">

  <div class="layout margin flex-std" [@routerTransition]="'fly'">

    <div class="layout padding-sm" fxLayout.gt-md="row" fxLayout="column" fxLayoutAlign.gt-md="center start" fxLayoutAlign="center center" fxFlex="100"
      fxLayoutGap="24px">

      <div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign.xs="center center">
        <ifb-broker-status-widget [broker]="data?.broker" [edit]="canEditBrokerInfo"></ifb-broker-status-widget>
        <ifb-broker-commission-widget [broker]="data?.broker" [edit]="canEditBrokerInfo"></ifb-broker-commission-widget>
      </div>

      <div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign.xs="center center">
        <ifb-broker-company-widget [broker]="data?.broker" [edit]="canEditBrokerInfo"></ifb-broker-company-widget>
        <ifb-broker-branding-widget [broker]="data?.broker"></ifb-broker-branding-widget>
      </div>

      <div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign.xs="center center">
        <ifb-broker-other-info-widget [broker]="data?.broker" [edit]="canEditBrokerInfo"></ifb-broker-other-info-widget>
        <ifb-business-advisor-widget [data]="data?.broker" [canEditAdvisor]="canEditBrokerInfo" (edit)="changeAdvisor()"></ifb-business-advisor-widget>
        <ifb-broker-activity-widget [broker]="data?.broker"></ifb-broker-activity-widget>
      </div>

    </div>

  </div>

</ifc-app-scroll-container>